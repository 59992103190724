(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/template/assets/javascripts/template.js') >= 0) return;  svs.modules.push('/components/core/template/assets/javascripts/template.js');

'use strict';

var templateConfig = svs.core.template.data ? svs.core.template.data.templateConfig : {};

svs.core.template.getConfig = function() {
  return templateConfig;
};

svs.core.template.updateConfig = function(config) {
  var keysToUpdate = Object.keys(config);

  keysToUpdate.forEach(function(key) {
    templateConfig[key] = config[key];
  });

  svs.core.template.trigger('configUpdate', { config: templateConfig, changed: keysToUpdate });
};

svs.core.template = new svs.core.Events(svs.core.template);


 })(window);